import React from "react";
import {  graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

import LayoutLp from "../components/layoutLp";
import LpHolisticHeroImg from "../components/lp-holisticHeroImage";
import SEO from "../components/seo";
import VoiceShort from "../components/voiceShort";
import HookFormsLp from "../components/hookFormsLp";

import InfoNishiogi from "../components/infoNishiogi";

import Cta from "../components/cta";
import Recommend from "../components/recommend";

import Divider from "../static/svg/divider.inline.svg"



function IndexPage({ location }) {
  const {pathname} = location;

  const pagetitle = "西荻窪の整体≪ぺんぎん堂≫";
  const description = "西荻窪から徒歩3分のアメリカ式整体・オステオパシ―専門院。なかなかとれないコリや痛みにお困りの方へ";

  return (
   <LayoutLp>  
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
        isLp
      />
    <LpHolisticHeroImg />


      <section className="text-center mx-3.5">

        <div className="text-left mx-1 md:mx-4">
          <div className="w-full max-w-sm mx-auto mb-12">
            <h1>
              <StaticImage
                src="../images/fucho-reason.png"
                alt="不調の原因はどこにある？"
                placeholder="blurred"
                layout="constrained"
              />
            </h1>
          </div>


          <p className="sm:mt-3">整骨院や整体院で、こんな言葉を良く見かけませんか？</p>
          <div className="bg-emerald-50 px-3 mx-3 rounded-xl">
            <ul className="list-disc  py-3">
              <li className="my-2">あなたの痛み、<span className="marker">その原因は痛い場所にはありません</span></li>
              <li className="my-2">骨盤や背骨を矯正して、<span className="marker">根本から</span>治します</li>
            </ul>
          </div>
          <p className="mt-5">
            本当にそのとおりで、原因が痛い場所にないことは多いです。</p><p className="my-5">さらに、こんな言葉も見かけませんか？</p>

          <div className="bg-emerald-50 px-3 mx-3 pb-4 rounded-xl">
            <ul className="list-disc bg-emerald-50 py-3">
              <li className="my-2">「腰痛の原因は<span className="marker">腸腰筋</span>が9割」</li>
              <li className="my-2">「<span className="marker">骨盤の歪み</span>が腰の痛みを引き起こす」</li>
              <li className="my-2">「肩こりの原因は<span className="marker">ストレートネック</span>」</li>
            </ul>


            <div className="flex flex-row">
              <div className="m-0.5">
                <StaticImage
                  src="../images/bone.jpg"
                  alt="脊柱の歪み"
                  placeholder="tracedSVG"
                  layout="constrained"
                  width={250}
                  className=""
                />
              </div>

              <div className="m-0.5">
                <StaticImage
                  src="../images/muscle.jpg"
                  alt="疲れた筋肉"
                  placeholder="tracedSVG"
                  layout="constrained"
                  width={250}
                  className=""
                />
              </div>
            </div>
          </div>

          <p className="mt-5 mb-2">骨盤、背骨、筋肉、関節…</p><p className="mb-5">原因は、<span className="marker">まだ他にもあるんです</span>。</p><p>何でしょう？&#129300;</p>

          <div className="overflow-hidden">
            <div className="w-full max-w-sm mt-16 mx-auto  mb-12">
              <h2>
                <StaticImage
                  src="../images/fucho-reason-others.png"
                  alt="骨盤、背骨、筋肉、関節、他には？"
                  placeholder="blurred"
                  layout="constrained"
                  className="w-full"
                />
              </h2>
            </div>

            <div className="table mt-3 sm:mt-6">
              <div className="table-cell w-1/4">
                <StaticImage
                  src="../images/penguin-sensei.jpg"
                  alt="ぺんぎん博士"
                  placeholder="blurred"
                  layout="constrained"
                  width={250}
                  className=""
                />
              </div>
              <div className="table-cell w-3/4">
                <div className="p-1 ml-1">
                  <div className="arrow_box">
                    <div className="">
                      <p>答えは、</p>
                      <ul className="list-disc py=0.5">
                        <li className="my-2 text-lg"><span className="marker">血流・リンパの流れ</span></li>
                        <li className="my-2 text-lg"><span className="marker">内臓の動き</span></li>
                        <li className="my-2 text-lg"><span className="marker">体全体のバランスの崩れ</span></li>
                        <li className="my-2 text-lg"><span className="marker">自律神経の乱れ</span></li>
                        <li className="my-2 text-lg"><span className="marker">頭（頭蓋骨や脳脊髄液）の動き</span></li>
                      </ul>
                      <p>です。</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>




            <p className="my-5">姿勢が悪くて、<span className="sm:hidden"><br /></span>内臓の動きが悪くなってしまい<span className="sm:hidden"><br /></span>腰痛になることもあります。</p>
            <p className="my-5">ぺんぎん堂では、<span className="marker">アメリカの整体・オステオパシーという方法</span>で、全身調整します。 </p>

            <p className="my-5">リンパマッサージをしたり、頭専門のヘッドスパをしたりするところは比較的多くありますが、<span className="marker">筋肉も、脊柱も、関節も、リンパも、頭も、内臓も、全て施術の対象としているのはオステオパシーだけ</span>です。</p>
            <p>
              <span className="marker">骨盤と背骨だけの調整ではだめだったコリや痛みに、<span className="sm:hidden"><br /></span>本物の全身調整をしてみませんか？</span></p>
          </div>

          <p className="mt-3">
          
          <Link to='/lp-osteopathy/' className="underline text-blue-800 hover-hover:hover:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline pb-0.5" />オステオパシーについてもっと読む
          </Link>
  
          </p>





          <hr className="my-5" />


          <div className="overflow-hidden">
            <StaticImage
              src="../images/seitai-session1.jpg"
              alt="整体・オステオパシー施術の様子"
              placeholder="dominant"
              layout="constrained"
              width={400}
              className="mr-5 mt-3 mb-4 ml-2"
            />
                <div className="arrow_box_t p-3 text-slate-900 ">
            <p className="mb-5"> はじめまして。ぺんぎん堂で身体の調整をしております、橋本智子と申します。</p>
            <p className="my-5"> クライアントさんのお身体とじっくり対話するようなイメージで施術をしております。</p>
            <p className="my-5"> お身体にお困りのことがあったら、ぺんぎん堂にご相談ください(^^)/</p>
            <p className="mt-3">
            
          <AnchorLink to="/lp-holistic#profile"  className="underline text-blue-800 hover-hover:hover:text-blue-900"
          >
              <ChevronDoubleRight className="h-4 w-4 align-middle inline pb-0.5" />ごあいさつをもっと読む
          </AnchorLink>

          </p>

            </div>
          </div>

          <hr className="my-8" />

          <p>肩や腰のがんこなコリは、</p>
          <p className="my-5">
            複数の原因が重なって、<span className="sm:hidden"><br /></span>ますます頑固に硬くなっていくこともあります。</p>


          <p className="my-5">整体・施術が終わった後に、</p>
          <div className="table">
            <div className="table-cell w-1/4 align-top mt-3 text-center">
              <StaticImage
                src="../images/womanface3.png"
                alt="女性のお客様"
                placeholder="blurred"
                layout="constrained"

                className="mr-2 mt-5"
              />
            </div>
            <div className="table-cell w-3/4 align-top">
              <div className="p-1 ml-1">
                <div className="arrow_box">
                  <ul className="list-disc mb-1 py-0.5">
                    <li className="mt-2 mb-4">「首も軽くなったんですが、<span className="marker  text-lg">とにかく目がスッキリ！</span>」</li>
                    <li className="my-2">「なんとなく頭がぼーっとしてたのが、<span className="marker  text-lg">スッとしました。</span>」</li>
                  </ul>
                </div></div>
            </div>
          </div>

          <p className="my-5">という感想をいただくことがあります。</p>
          <p className="my-5">
            首や肩が凝り固まっていると、<span className="sm:hidden"><br /></span>首から上の血流が悪くなるので、<span className="sm:hidden"><br /></span>目が疲れたり、頭痛につながってしまうことも。</p>

          <p className="my-5">そんなつらい状態になる前に、</p><p className="my-5 text-lg"><span className="marker">ガチガチで不快な肩こりや首のコリを原因から取り除いて</span>しまいませんか？</p>



          <hr className="my-10" />

          <VoiceShort isShowButton={false} />
          <hr className="my-4" />
          <Cta link="/lp-holistic#form" idForGtm="cta-view" />
          <hr className="my-10" />

          <InfoNishiogi />
          <hr className="my-10" />
          <Recommend />




          <div className="overflow-hidden">

            <h2 id="profile" className="heading-b my-6 text-center">ごあいさつ</h2>
            <div className="overflow-hidden">
              <StaticImage
                src="../images/profile.jpg"
                alt="ぺんぎん堂施術者プロフィール写真"
                placeholder="blurred"
                layout="constrained"
                width={150}
                className="xxs:float-left mr-5 mt-3 mb-4 ml-2 "
              />
              <p className='font-semibold'>身体の調整&nbsp;ぺんぎん堂<br />橋本&nbsp;智子</p>
            </div>
            <p> はじめまして。ぺんぎん堂で身体の調整をしております、橋本智子と申します。</p>
            <p className="my-5"><span className="marker">「整体」と聞くと何を想像されますか？</span></p>
          </div>
          <p className="my-5">カイロプラクティック、中国整体、骨盤調整、もみほぐし整体など、たくさんありますが…</p>
          <p className="my-5">ぺんぎん堂で行っている整体は、<span className="sm:hidden"><br /></span><span className="marker">アメリカ発祥の”オステオパシー”</span>という方法です。</p>
          <p>電気などの道具は使わず、手を使って身体の調整をします。</p>
          <p><span className="marker">施術中に寝てしまう方も多い</span>、やさしい施術です。</p>

          <p>全身をチェックして施術するので、腰痛や肩こりといった筋肉や関節の不調に加えて、</p>
          <ul>
            <li>・&nbsp;疲れが抜けない</li>
            <li>・&nbsp;生理痛がひどい</li>
            <li>・&nbsp;足がむくむ</li>
          </ul>
          <p>など、様々な不調の調整させていただいております。</p>


          <div className="mt-4">
            <h3 className="heading-l">ぺんぎん堂の施術者の資格</h3>
          </div>

          <p className="my-5">整体は色々ありますが、どれも国や自治体が定めた資格制度がありません。</p>
          <p className="my-5">整体を始めるまでの教育の内容や、整体師のレベルはまちまちです。</p>
          <p className="my-5"><span className="marker">整体院や施術者選びは、慎重に調べてから</span>というかたも多いと思います。</p>
          <div className="overflow-hidden">

            <StaticImage
              src="../images/cranial-field.jpg"
              alt="頭蓋領域のオステオパシー"
              placeholder="blurred"
              layout="constrained"
              className="max-w-17 sm:float-left mr-5 mt-3 mb-4 ml-2 w-full"
            />

            <p className="my-5">ぺんぎん堂の施術者は、<span className="sm:hidden"><br /></span>アメリカのオステオパシードクターが公認している<span className="sm:hidden"><br /></span>3年制のオステオパシー専門教育機関を<span className="sm:hidden"><br /></span>卒業しております。</p>
            <p className="my-5">オステオパシーの国際ライセンスも取得しております。</p>
            <p className="my-5">解剖学や生理学なども含めて、<span className="sm:hidden"><br /></span><span className="marker">基礎から学んだうえでの安全な整体</span>です。</p>
            <p className="my-5">どうぞご安心してご来院ください。</p>
          </div>
          <Divider className="w-full mb-6" />
          <p><span className="marker">「丁寧に、謙虚に、全力で！」</span>が私の施術家としてのモットーです。</p><p>クライアントさんのお身体とじっくり対話するようなイメージで施術をしております。</p>

          <p>長年の痛みや不調にお悩みの場合、快方に向かうまで時間がかかることもあります。</p>
          <div className="arrow_box_b mt-5">
            <p>&nbsp;<span className="text-pink-400">◆</span> オステオパシーの施術でコリや不調を和らげ、<br />&nbsp;<span className="text-pink-400">◆</span> 日々の生活の中で気にしていただきたいことや身体の使い方などもお話しして、<br />&nbsp;<span className="text-pink-400">◆</span> クライアントさんに寄り添う施術を提供していくこと</p>
          </div><p>を心掛けております。</p>

          <p className="mb-6">お身体にお困りのことがあったら、ぺんぎん堂にご相談ください(^^)/</p>









          <div id="form" className="mb-6 pt-4 md:pt-8 px-3 py-6 bg-yellow-100">

            <HookFormsLp formName="lp-holistic" />
          </div>

           <Cta link="/lp-holistic#form" /> 
        </div>
      </section>
     </LayoutLp> 
  );
}

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
` 