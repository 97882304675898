import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import PenLogo from "../static/svg/pen-logo.inline.svg";

const SvgBg = styled.div`

  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20xmlns%3Asvgjs%3D%22http%3A%2F%2Fsvgjs.dev%2Fsvgjs%22%20viewBox%3D%220%200%20700%20700%22%20width%3D%22700%22%20height%3D%22700%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22nnnoise-filter%22%20x%3D%22-20%25%22%20y%3D%22-20%25%22%20width%3D%22140%25%22%20height%3D%22140%25%22%20filterUnits%3D%22objectBoundingBox%22%20primitiveUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22linearRGB%22%3E%20%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.102%22%20numOctaves%3D%224%22%20seed%3D%2215%22%20stitchTiles%3D%22stitch%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20result%3D%22turbulence%22%3E%3C%2FfeTurbulence%3E%20%3CfeSpecularLighting%20surfaceScale%3D%2215%22%20specularConstant%3D%220.75%22%20specularExponent%3D%2220%22%20lighting-color%3D%22hsl(22.5%2C%20100%25%2C%2033%25)%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20in%3D%22turbulence%22%20result%3D%22specularLighting%22%3E%20%3CfeDistantLight%20azimuth%3D%223%22%20elevation%3D%22100%22%3E%3C%2FfeDistantLight%3E%20%3C%2FfeSpecularLighting%3E%20%3C%2Ffilter%3E%3C%2Fdefs%3E%3Crect%20width%3D%22700%22%20height%3D%22700%22%20fill%3D%22hsl(51%2C%2061%25%2C%2087%25)%22%3E%3C%2Frect%3E%3Crect%20width%3D%22700%22%20height%3D%22700%22%20fill%3D%22hsl(22.5%2C%20100%25%2C%2033%25)%22%20filter%3D%22url(%23nnnoise-filter)%22%3E%3C%2Frect%3E%3C%2Fsvg%3E");
  
`;


function LpHolisticHeroImg() {
    return (
<>

    <div className="max-w-3xl text-center m-auto">
    <SvgBg className="lp-hero flex-col m-auto  relative">
    <div className="w-full">
    <StaticImage
          src="../images/lp-top.png"
          alt="肩がバリバリ、腰が重い、疲れが取れない、瀬永が痛いとため息をつく女性"
          placeholder="tracedSVG"
          layout="constrained"
          loading="eager"
        />
    </div>
    </SvgBg>
   
    
    <div className="arrow_box_t py-2 sm:p-3 text-slate-900 ">
        <p className="text-base mb-0   text-center sm:text-lg md:text-xl font-semibold">西荻窪 徒歩3分<span  className="xs:hidden"><br /></span>&nbsp;&nbsp;アメリカ式整体 オステオパシー</p><p className="text-center  text-xl sm:text-2xl md:text-3xl">
        <PenLogo className="inline-block mr-1.5 -mt-1.5 width='10px' height='10px'" />身体の調整&nbsp;ぺんぎん堂</p>
        <p className="text-amber-900 text-center font-bold text-2xl sm:text-3xl md:text-rxl">なかなか取れない<span  className="sm:hidden"><br /></span>コリや痛みに</p>
    </div>


    <div className="max-w-3xl  text-center pt-5 mt-0 mb-1  mx-auto bg-emerald-50">

                    
        <div className="pt-8 pb-4 max-w-xl bg-emerald-50 mb-16 mx-auto">
            <StaticImage
              src="../images/manga.png"
              alt="オステオパシーで、骨盤だけではない脊柱だけでもない全身調整"
              placeholder="tracedSVG"
              layout="constrained"
            />
            </div>

            </div>

            </div>


    </>
    )
};

export default LpHolisticHeroImg;